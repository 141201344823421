<template>
  <div class="-m-20px hhh p-20px bg-img">
    <div class="">
      <Headers></Headers>
    </div>
    <div class="mt-220px">
      <LuckyWheel
      class="mx-auto"
      ref="myLucky"
      :width="`${luckyWheelSize}px`"
      :height="`${luckyWheelSize}px`"
      :prizes="prizes"
      :blocks="blocks"
      :buttons="buttons"
      :defaultConfig="defaultConfig"
      @start="startCallback"
      @end="endCallback"
    />
    </div>
    <div class=" text-gray-800 text-14px leading-20px mt-10px">
      <div>规则：</div>
      <div>
        <p class="mt-10px">
          1.
          开奖当天的上一个交易日美国股市收盘时的苹果公司美股价格的小数点后2位+
          谷歌美股价格的小数点后2位+特斯拉美股价格的小数点后2位+亚马逊美股价格的小数点后2位+京东美股价格的小数点后2位=10位数（即5组数字合并，10位按顺序排列，首位为0则去掉）。
        </p>
        <p class="mt-10px">
          2.
          以此10位数整除以截止至开奖日前一天23:59:59参加本次活动的人数（每个抽奖号为一个人数），得到的余数加1即为本次活动的最终获奖号码。
        </p>
        <p class="mt-10px">
          3. 如需要开多个奖，则需用报名人数除以产品总数得到一个区间数
          X，再用上述中奖号码+X*（N-1），得到其他中奖号码（N为获奖序号），如果得出的中奖数字不是整数，则采用四舍五入原则，获得一个整数作为中奖号码。
        </p>
      </div>
    </div>


    <el-dialog class="dialog" title="" :visible.sync="outerVisible" top="40vh" width="300px" :show-close="false">
      <template #title>
        <div></div>
      </template>
      <div>1</div>
    </el-dialog>


  </div>
</template>
<script>
import { LuckyWheel } from '@lucky-canvas/vue'
import Headers from '../components/headers.vue'
const luckyWheelSize = 300
export default {
  components: {
    LuckyWheel,
    Headers,
  },
  data() {
    return {
      outerVisible:false,
      luckyWheelSize: luckyWheelSize,
      defaultConfig: {
        offsetDegree: 360 / 6 / 2,
      },
      blocks: [
        {
          imgs: [
            {
              src: require('@/assets/lucky/lucky_bg.png'),
              top: 0,
              width: luckyWheelSize,
              height: luckyWheelSize,
              rotate: true,
            },
          ],
        },
      ],
      prizes: [
        {
          imgs: [
            { src: require('@/assets/lucky/TY.png'), top: '35%', width: 100 },
          ],
        },
        {
          imgs: [
            { src: require('@/assets/lucky/JD.png'), top: '35%', width: 100 },
          ],
        },
        {
          imgs: [
            { src: require('@/assets/lucky/TM.png'), top: '35%', width: 100 },
          ],
        },
        {
          imgs: [
            { src: require('@/assets/lucky/XZ.png'), top: '35%', width: 100 },
          ],
        },
        {
          imgs: [
            { src: require('@/assets/lucky/KT.png'), top: '35%', width: 100 },
          ],
        },
        {
          imgs: [
            { src: require('@/assets/lucky/TYJ.png'), top: '35%', width: 100 },
          ],
        },
      ],
      buttons: [
        {
          radius: '30px',
          background: '#617df2',
          imgs: [
            {
              src: require('@/assets/lucky/lucky_in.png'),
              top: -45,
              width: 90,
            },
          ],
        },
      ],
    }
  },
  methods: {
    // 点击抽奖按钮会触发star回调
    startCallback() {
      // 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play()
      // 模拟调用接口异步抽奖
      setTimeout(() => {
        // 假设后端返回的中奖索引是0
        const index = 0
        // 调用stop停止旋转并传递中奖索引
        this.$refs.myLucky.stop(index)
      }, 3000)
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      console.log(prize)
    },
  },
}
</script>
<style lang="scss" scoped>
.hhh{
  // height: calc(100vh - env(safe-area-inset-bottom));
  min-height: 100vh;
  margin-bottom: calc(-70px - env(safe-area-inset-bottom));
}
.bg-img{
  background: url('~@/assets/lucky/bg.png') no-repeat,#fdb472;
  background-size:100%;
}
::v-deep .dialog{
  
  .el-dialog{
    background: transparent;
    box-shadow: none;
  }
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 0;
    background: transparent;
  }
}
</style>